export const DOMAINS = {
  PERSONAL: 'https://personal.vanguard.com',
  PERSONAL1: 'https://satpersonal1.vanguard.com',
  RETIREMENT_PLANS: 'https://retirementplans.vanguard.com',
  APPS: 'https://apps.vanguard.com',
  PELANDER: 'https://pelander.vanguard.com',
  HOLDINGS: 'https://holdings.web.vanguard.com',
  BALANCES: 'https://balances.web.vanguard.com',
  WATCHLIST: 'https://funds-watchlist.web.vanguard.com',
  BYD: 'https://balance-by-date.web.vanguard.com',
  INVESTOR: 'https://investor.vanguard.com',
};

export const URLS = {
  ALTERNATE_TRADE_PATH: `${DOMAINS.PERSONAL1}/atu-alternate-trade-path/contingency`,
};
