<ng-container content [bundleName]="'flagshipContent'" #content="bundle">
  <div class="flagship-contact">
    <h2 class="flagship-contact__header--lg">{{ content.flagshipLabel }}</h2>
    <div class="flagship-contact__info d-flex flex-column flex-lg-row">
      <div class="flagship-contact__info__rep">
        <div class="flagship-contact__header--sm">{{ content.flagshipSubtitle }}</div>
        <div class="flagship-contact__info__contact">
          <div class="flagship-contact__header--sm">{{ content.contactInformationLabel }}</div>
          <p class="pb-2">
            {{ flagshipData.supportPhoneNumber }}
          </p>
          <p>{{ flagshipData.supportSchedule.days }}</p>
          <p>{{ flagshipData.supportSchedule.hours }}</p>
        </div>
        <br />
        <div class="flagship-contact__info__links">
          <a
            c11n-link
            variant="secondary-independent"
            size="medium"
            href="{{ flagshipData.secureMessageUrl }}"
            fontWeight="normal"
          >
            {{ content.sendSecureMessageLabel }}
          </a>
          <br />
        </div>
      </div>
    </div>
  </div>
</ng-container>
