<ss-lib-page-layout>
  <greetings-widget headline [currentBalance]="greetingBalance" [valueAsOfDate]="greetingAsOfDate"></greetings-widget>
  <div inner>
    <div class="row">
      <div class="col">
        <div class="d-print-none">
          <ss-lib-secondary-nav widgetActiveItem="AccountsTab" [displayPrintLink]="true"></ss-lib-secondary-nav>
        </div>
        <div class="d-none d-print-block mb-3 mt-3 balancesTab">
          <p class="balancesTabText">Balances</p>
        </div>
      </div>
    </div>
    <!-- Marketing A-SPOT container -->
    <ng-container *gatekeeper="'interact'">
      <div id="target-aspot-offer" class="target-web-offer">
      </div>
    </ng-container>
    <div class="row">
      <div class="col">
        <ss-lib-customize-view
          [displayCustomizeViewLink]="displayCustomizeViewLink"
        ></ss-lib-customize-view>
      </div>
    </div>
    <div class="row d-print-none">
      <div class="col">
        <app-client-alerts></app-client-alerts>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <app-balances></app-balances>
      </div>
    </div>
    <div class="row d-print-none">
      <div class="col">
        <app-links></app-links>
      </div>
    </div>
    <div *gatekeeper="'yourAdvisor'" class="row d-print-none">
      <hr />
      <div class="col">
        <app-contact-us></app-contact-us>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <app-disclaimers
          [hasCashManagementAccount]="hasCashManagementAccount"
          [hasPrivateEquityAccount]="hasPrivateEquityAccount"
          [hasPersonalTrustAccount]="hasPersonalTrustAccount">
        </app-disclaimers>
      </div>
    </div>
  </div>
</ss-lib-page-layout>
