import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactUsComponent } from './contact-us.component';
import { RepresentativeContactModule } from '../representative-contact/representative-contact.module';

import { FlagshipServicesModule } from '../flagship-services/flagship-services.module';

@NgModule({
  declarations: [ContactUsComponent],
  imports: [CommonModule, RepresentativeContactModule, FlagshipServicesModule],
  exports: [ContactUsComponent],
})
export class ContactUsModule {}
