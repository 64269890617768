<ng-container *ngIf="repContactList.length || flagshipData">
  <div class="contact-us">
    <div class="row">
      <ng-container *ngIf="repContactList">
        <div *ngFor="let rep of repContactList" class="col-lg">
          <app-representative-contact [repContactData]="rep"></app-representative-contact>
        </div>
      </ng-container>
      <ng-container *ngIf="flagshipData">
        <div class="col-lg">
          <app-flagship-services [flagshipData]="flagshipData"></app-flagship-services>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>
