import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseComponent } from './base.component';
import { BalancesModule } from '../balances/balances.module';
import { DisclaimersModule } from '../disclaimers/disclaimers.module';
import { LinksModule } from '../links/links.module';
import { HttpClientModule } from '@angular/common/http';
import {
  PageLayoutModule,
  SecondaryNavigationModule,
  CustomizeViewModule,
} from '@vanguard/secure-site-components-lib';
import { ServicesModule } from '@app/services/services.module';
import { ClientAlertsModule } from '../client-alerts/client-alerts.module';
import { ContactUsModule } from '../contact-us/contact-us.module';
import { GatekeeperModule } from '@vanguard/secure-site-components-lib';

@NgModule({
  declarations: [BaseComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    BalancesModule,
    DisclaimersModule,
    LinksModule,
    HttpClientModule,
    ServicesModule,
    PageLayoutModule,
    SecondaryNavigationModule,
    ClientAlertsModule,
    ContactUsModule,
    GatekeeperModule,
    CustomizeViewModule,
  ],
  providers: [],
})
export class HomeModule {}
