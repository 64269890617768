import { Component, Input } from '@angular/core';
import { FlagshipServices } from '@models/flagship-services.model';

@Component({
  selector: 'app-flagship-services',
  templateUrl: './flagship-services.component.html',
  styleUrls: ['./flagship-services.component.scss'],
})
export class FlagshipServicesComponent {
  @Input() flagshipData: FlagshipServices;
  constructor() {}
}
