import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RepresentativeContactComponent } from './representative-contact.component';
import { LinkModule } from '@vg-constellation/angular-14/link';
import { DirectivesModule } from '@app/directives/directives.module';
import { SSLibPipesModule } from '@vanguard/secure-site-components-lib';

@NgModule({
  declarations: [RepresentativeContactComponent],
  imports: [CommonModule, LinkModule, DirectivesModule, SSLibPipesModule],
  exports: [RepresentativeContactComponent],
})
export class RepresentativeContactModule {}
