import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BalancesService } from '@app/services/balances/balances.service';
import {
  ContingencyService,
  ContingencyFlags,
} from '@app/services/contingency/contingency.service';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss'],
})
export class BaseComponent implements OnInit {
  greetingBalance: number | 'loading' = 'loading';
  greetingAsOfDate: string | undefined;
  displayCustomizeViewLink: boolean;
  hasCashManagementAccount: boolean = false;
  hasPrivateEquityAccount: boolean = false;
  hasPersonalTrustAccount: boolean = false;
  isLimited: boolean = false;
  contingencyFlags: ContingencyFlags | undefined = {
    isContingency: false,
    isIPL: false,
    alternateTradePaths: {
      isEtfsAlternateTradePath: false,
      isMutualFundsAlternateTradePath: false,
    },
  };

  constructor(
    private readonly balancesService: BalancesService,
    private readonly route: ActivatedRoute,
    private readonly contingencyService: ContingencyService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.contingencyFlags = await this.contingencyService.getContingencyFlags(this.route.snapshot);
    this.isLimited = this.contingencyFlags?.isContingency || this.contingencyFlags?.isIPL || false;
    this.displayCustomizeViewLink = true;
    this.hasCashManagementAccount = await this.balancesService.getHasCashManagementAccount();
    this.hasPrivateEquityAccount = await this.balancesService.getHasPrivateEquityAccount();
    this.hasPersonalTrustAccount = await this.balancesService.getHasPersonalTrustAccount();
    this.balancesService.getBalancesData().then((accountsInfo) => {
      this.greetingBalance = accountsInfo?.totals?.totalBalance as number;
      this.greetingAsOfDate = accountsInfo?.totals?.totalBalanceAsOfDate as string;
    });
  }
}
