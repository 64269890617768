<ng-container content [bundleName]="'contactUsContent'" #content="bundle">
  <div class="rep-contact mb-5">
    <h2 class="rep-contact__header--lg">{{ repContactData.label | emDash }}</h2>

    <div class="rep-info row">
      <!--image-->
      <div class="rep-contact__img col-md-3 col-12">
        <div class="rep-image" *ngIf="!replaceRepImage; else repAlternateImage">
          <img
            alt="{{ repContactData.label }}'s photo"
            src="{{ repContactData.webImagePath }}"
            (error)="missingRepImage($event)"
          />
        </div>
        <ng-template #repAlternateImage>
          <svg
            width="82px"
            height="82px"
            viewBox="0 0 64 64"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <title>Large/Image</title>
            <g id="Holdings" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g id="Icon">
                <circle id="background-color" fill="#E8E9E9" cx="32" cy="32" r="32"></circle>
                <path
                  d="M32,35 C24.8495997,35 18.4764595,39.3416532 16.0968583,45.8338943 C15.9139873,46.3307904 15.9930326,46.8822393 16.3088557,47.3128664 C16.6246789,47.7434936 17.1379686,47.9996979 17.685486,48 L46.314514,48 C46.8620314,47.9996979 47.3753211,47.7434936 47.6911443,47.3128664 C48.0069674,46.8822393 48.0860127,46.3307904 47.9031417,45.8338943 C45.5235405,39.3416532 39.1504003,35 32,35 Z"
                  id="Path"
                  fill="#040505"
                ></path>
                <path
                  d="M24,24 C24,28.418278 27.581722,32 32,32 C36.418278,32 40,28.418278 40,24 C40,19.581722 36.418278,16 32,16 C27.581722,16 24,19.581722 24,24 Z"
                  id="Path"
                  fill="#040505"
                ></path>
              </g>
            </g>
          </svg>
        </ng-template>
      </div>

      <!--title-->
      <div class="rep-contact__info__rep col">
        <div class="rep-contact__header--sm">{{ repContactData.name }}</div>
        <p>{{ repContactData.title }}</p>
        <br />
      </div>
    </div>

    <!--contact info-->
    <div class="row">
      <div class="rep-contact__info__contact col-sm-12 col-9 offset-md-3">
        <div class="rep-contact__header--sm">{{ content.contactInformationLabel }}</div>
        <p class="pb-2">
          {{ repContactData.directPhoneNumber || repContactData.clientSupportPhoneNumber }}
        </p>
        <div *ngIf="repContactData.workingSchedule.length">
          <p>{{ repContactData.workingSchedule[0].days }}</p>
          <p>{{ repContactData.workingSchedule[0].hours }}</p>
        </div>
        <br />
      </div>
    </div>

    <!--links-->
    <div class="rep-contact__info__links row">
      <div *ngIf="repContactData.appointmentUrl" class="col-sm-12 col-9 offset-md-3 mb-3">
        <a
          c11n-link
          variant="secondary-independent"
          size="medium"
          href="{{ repContactData.appointmentUrl }}"
          fontWeight="normal"
        >
          {{ content.scheduleAppointmentLabel }}
        </a>
      </div>
      <div class="col-sm-12 col-9 offset-md-3 mb-3">
        <a
          c11n-link
          variant="secondary-independent"
          size="medium"
          href="{{ repContactData.secureMessageUrl }}"
          fontWeight="normal"
        >
          {{ content.sendSecureMessageLabel }}
        </a>
        <br />
      </div>
    </div>
  </div>
</ng-container>
