export const environment = {
  production: true,
  performance: false,
  internal: false,
  deployment: 'production',
  VGN_HEADER_FOOTER_JS: 'https://n4v.web.vanguard.com/secure-site-components.js',
  VGN_HEADER_FOOTER_STYLE: 'https://n4v.web.vanguard.com/nav-preload.css',
  CONSUMER_API: 'https://personal1.vanguard.com/xs1-secure-site-consumer-api/',
  LOGON_URL: 'https://logon.vanguard.com/logon',
  TEALEAF_LIB: 'https://personal1.vanguard.com/xs1-secure-site-consumer-api/tealeaf',
  GRAPHQL_URI: 'https://personal1.vanguard.com/xs1-secure-site-consumer-api/graphql',
  LOGGER_URI: 'https://personal1.vanguard.com/xs1-secure-site-consumer-api/api/log',
  GATEKEEPER_URI: 'https://personal1.vanguard.com/xs1-secure-site-consumer-api/api/gatekeeper',
  PERSONAL_DOMAIN: 'https://personal.vanguard.com',
  APPDYNAMICS_CONFIG: JSON.stringify({
    appKey: 'AD-AAB-ABU-ERR',
    adrumExtUrl: 'https://ccc.vgcontent.info/vg-app-dynamics/2.0.0/js',
    spa: { spa2: true },
    xd: { enable: false },
  }),
  CCC_APPDYNAMICS_JS: 'https://ccc.vgcontent.info/vg-app-dynamics/2.0.0/js/app_dynamics.min.js',
  CCC_SERVERLESS_JS:
    'https://mc3-static-assets.web.vanguard.com/vg-ccc-serverless/latest/vg-ccc-serverless.js',
  GRAPHQL_TIMEOUT: 40000,
  DASHBOARD_WIDGETS: 'https://dashboard-widgets.web.vanguard.com/build/web-components.esm.js',
  otelCollectorDomain: 'gto-ocp.icproxy.secp.c1.vanguard.com',
};
