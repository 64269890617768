import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlagshipServicesComponent } from './flagship-services.component';
import { LinkModule } from '@vg-constellation/angular-14/link';
import { DirectivesModule } from '@app/directives/directives.module';

@NgModule({
  declarations: [FlagshipServicesComponent],
  imports: [CommonModule, LinkModule, DirectivesModule],
  exports: [FlagshipServicesComponent],
})
export class FlagshipServicesModule {}
