import { Component, Input, OnInit } from '@angular/core';
import { RepresentativeContact } from '@models/representative-contact.model';

@Component({
  selector: 'app-representative-contact',
  templateUrl: './representative-contact.component.html',
  styleUrls: ['./representative-contact.component.scss'],
})
export class RepresentativeContactComponent implements OnInit {
  @Input() repContactData: RepresentativeContact;
  replaceRepImage: boolean;

  constructor() {}

  ngOnInit(): void {
    this.replaceRepImage = !this.repContactData.label; // Don't show image if we don't have a label for it.
  }

  missingRepImage(event: Event): void {
    this.replaceRepImage = true;
  }
}
