export const DOMAINS = {
  PERSONAL: 'https://satpersonal.vanguard.com:53443',
  PERSONAL1: 'https://satpersonal1.vanguard.com',
  RETIREMENT_PLANS: 'https://satretirementplans.vanguard.com:53443',
  APPS: 'https://satapps.vanguard.com',
  PELANDER: 'https://pelander-test.vanguard.com',
  HOLDINGS: 'https://holdings.webt.vanguard.com',
  BALANCES: 'https://balances.webt.vanguard.com',
  WATCHLIST: 'https://funds-watchlist.webt.vanguard.com',
  BYD: 'https://balance-by-date.webt.vanguard.com',
  INVESTOR: 'https://satinvestor.vanguard.com',
};

export const URLS = {
  ALTERNATE_TRADE_PATH: `${DOMAINS.PERSONAL1}/atu-alternate-trade-path/contingency`,
};
