import { DOMAINS, URLS } from './domains';

export const content = {
  balancesTable: {
    managedLabel: 'Advised accounts',
    selfManagedLabel: 'Self-managed accounts',
    totalVgAssetsLabel: 'Total Vanguard assets',
    totalPeAssetsLabel: 'Total HarbourVest assets',
    privateEquityHeaderLabel: 'Accounts held at HarbourVest',
    outsideInvestmentsLabel: 'Outside investments',
    balancesByDateLabel: 'Balances by date',
    balancesByDateUrl: `${DOMAINS.BYD}`,
    holdingsSummaryLabel: 'Go to Holdings summary',
    holdingsSummaryUrl: `${DOMAINS.HOLDINGS}/`,
    participantHomePageUrl: `${DOMAINS.PELANDER}/route`,
    trackYourTransferLink: `${DOMAINS.PERSONAL}/us/AssetTransferSummary?AssetTransferLaunchLocation=Overview&AssetTransferAccountId=`,
    trackYourTransferText: 'Track your transfer',
    mergeExpandPendingText: '(Upgrade in progress)',
    isBeneficiaryLabel: ' (Personal trust account)',
    isCoTrusteeLabel: ' (Personal trust account - Co-trustee)',
  },
  linkedToLabels: {
    linkedVBA: 'Linked to Brokerage Account-',
    linkedSungard: 'Linked to Personal Trust Account-',
  },
  clientAlerts: {
    alertHeader: 'Immediate action required!',
    returnByPostOfficeLabel: 'confirm or edit',
    returnByPostOfficeUrl: `${DOMAINS.PERSONAL}/us/AddressPhoneView`,
    returnByPostOffice: '• Your mail has been returned to Vanguard from the post office. Please',
    returnByPostOffice2: 'your address to remove the returned from post office (RPO) restriction.',
    recentlyAddedBankLabel: 'Review your bank information',
    recentlyAddedBankUrl: `${DOMAINS.PERSONAL}/us/XHTML/BankSummary`,
    recentlyAddedBank:
      '• A new bank has been added to your account or an existing bank has been edited.',
    institutionalError:
      '• Our system is temporarily unable to display some of your employer plan account information. We apologize for the inconvenience and appreciate your patience while we fix these issues. Please refresh in a few minutes or go to ',
    institutionalErrorLabel: 'Retirement Plans',
    institutionalErrorUrl: `${DOMAINS.PERSONAL}/us/LinktoMEP?hostname=GS_PE_HOSTNAME&url=GS_PE_ACCT_SUMMARY`,
    period: '.',
  },
  linksContent: {
    transactionalAside: {
      header: 'Buy and sell',
      links: [
        {
          url: `${DOMAINS.PERSONAL}/us/BuySellRouter?investmentType=MUTL&transactionType=BUY&nonRetirementMode=true`,
          label: 'Buy funds',
        },
        {
          url: `${DOMAINS.PERSONAL}/us/BuySellRouter?investmentType=MUTL&transactionType=SEL&nonRetirementMode=true`,
          label: 'Sell funds',
        },
        {
          url: `${DOMAINS.PERSONAL}/us/BuySellRouter?investmentType=MUTL&transactionType=EXCH&nonRetirementMode=true`,
          label: 'Exchange funds',
        },
        {
          url: `${DOMAINS.PERSONAL}/us/BuySellRouter?investmentType=EQUITY`,
          label: 'Trade ETFs',
        },
        {
          url: `${DOMAINS.PERSONAL}/us/openaccount`,
          label: 'Move an account to Vanguard',
        },
      ],
    },
    bSpot: {
      header: 'Your guide to investing',
      bodyText:
        "Whether you're saving for retirement, enjoying retirement life, or working toward a different goal, we're here to help. Find the support you need for each stage of your investing life.",
      links: [
        {
          url: `${DOMAINS.INVESTOR}/investor-resources-education`,
          label: 'Explore ways to help secure your future',
        },
      ],
    },
    clericalAside: {
      header: 'Frequently Requested Links',
      myWatchListUrl: `${DOMAINS.WATCHLIST}`,
      myLegacyWatchListUrl: `${DOMAINS.PERSONAL}/us/SecFundsWatchList`,
      myWatchListLabel: 'Watchlist',
      updateBeneficiariesUrl: `${DOMAINS.PERSONAL}/us/BeneficiariesServlet`,
      updateBeneficiariesLabel: 'Update beneficiaries',
      addABankUrl: `${DOMAINS.PERSONAL}/us/XHTML/AddABank`,
      addABankLabel: 'Add a bank',
      changeMyPasswordUrl: `${DOMAINS.PERSONAL}/us/MPSecurity01`,
      changeMyPasswordLabel: 'Change my password',
      viewMyStatementsUrl: `${DOMAINS.PERSONAL}/us/Statements`,
      viewMyStatementsLabel: 'View my statements',
      setUpAutomaticInvestingUrl: `${DOMAINS.PERSONAL}/us/AutomaticInvestmentOptionsController`,
      setUpAutomaticInvestingLabel: 'Set up automatic investing',
      getWireTransferInstructionsUrl: `${DOMAINS.PERSONAL}/us/WiresSelectAccount`,
      getWireTransferInstructionsLabel: 'Get wire transfer instructions',
      planCommunicationsUrl: `${DOMAINS.RETIREMENT_PLANS}/VGApp/pe/PlanCommunications.jsf#!/`,
      planCommunicationsLabel: 'Plan communications',
      retirmentPlanStatementsUrl: `${DOMAINS.RETIREMENT_PLANS}/VGApp/pe/faces/TransactionHistory.xhtml?gh_sec=y`,
      retirmentPlanStatementsLabel: 'Retirement plan statements',
    },
    secureMessage: {
      url: `${DOMAINS.PERSONAL}/us/SMCInformationCenter`,
    },
  },
  disclosuresContent: {
    brokerageDisclosure:
      'Vanguard funds not held in brokerage accounts are held by The Vanguard Group, Inc. and are not protected by SIPC.',
    personalTrustDisclosurePartOne:
      'The total balance shown at the top of this page is the combined total for all your displayed accounts—including your personal trust holdings. For additional questions or information, please contact your trust advisor.',
  },
  transactContent: {
    etfsAlternateTradePath: {
      labelText: 'Trade ETFs and stocks',
      href: `${URLS.ALTERNATE_TRADE_PATH}`,
    },
    buyMutualFundsAlternateTradePath: {
      labelText: 'Buy Vanguard funds',
      href: `${URLS.ALTERNATE_TRADE_PATH}/buy-mf`,
    },
    sellMutualFundsAlternateTradePath: {
      labelText: 'Sell Vanguard funds',
      href: `${URLS.ALTERNATE_TRADE_PATH}/sell-mf`,
    },
  },
  contactUsContent: {
    rmaClientLabel: 'Your Financial Advisor',
    flagshipClientLabel: 'Your Relationship Manager',
    scheduleAppointmentLabel: 'Schedule & manage appointments',
    sendSecureMessageLabel: 'Send a secure message',
    contactInformationLabel: 'Contact Information',
  },
  flagshipContent: {
    flagshipLabel: 'Flagship services',
    flagshipSubtitle: 'Contact information',
    sendSecureMessageLabel: 'Send a secure message',
  },
};
