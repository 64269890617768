import { Component, OnDestroy, OnInit } from '@angular/core';
import { ContactUsDataGQL, ContactUsDataQuery } from '@generated/graphql';
import { Subscription } from 'rxjs';
import { RepresentativeContact } from '@models/representative-contact.model';
import { GraphQLService } from '@app/services/graphql/graphql.service';
import { content } from '@content/content';
import { environment } from '@environments/environment';
import { FlagshipServices } from '@models/flagship-services.model';
import { ApolloQueryResult } from '@apollo/client/core';

type GQLContactUs = NonNullable<ContactUsDataQuery['contactUs']>;
// Define a type that excludes all RepresentativeContacts from GQLContactUs so that GQLContactUs can be only used for general info.
type GQLContactUsWithoutRepresentativeContacts = Omit<
  NonNullable<GQLContactUs>,
  'representativeContacts'
>;
type GQLRepresentativeContacts = GQLContactUs['representativeContacts'];
type GQLRepresentativeContact = GQLRepresentativeContacts[number];
type GQLSupportSchedules = GQLContactUs['supportSchedule'];

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
})
export class ContactUsComponent implements OnInit, OnDestroy {
  gqlSubscription: Subscription;
  repContactList: RepresentativeContact[] = [];
  flagshipData: FlagshipServices | undefined;
  hasRelationshipManager: boolean = false;
  constructor(
    private readonly representativeContactGQL: ContactUsDataGQL,
    private readonly gqlService: GraphQLService,
  ) {}

  ngOnInit(): void {
    this.loadContactInfo();
  }

  ngOnDestroy(): void {
    this.gqlSubscription?.unsubscribe();
  }

  loadContactInfo(): void {
    this.gqlSubscription = this.gqlService
      .query<ContactUsDataQuery>(this.representativeContactGQL.document)
      .subscribe((queryResult: ApolloQueryResult<ContactUsDataQuery>) => {
        const responseData: ContactUsDataQuery = queryResult.data;
        const gqlContactUs: GQLContactUs | undefined | null = responseData?.contactUs;
        if (gqlContactUs) {
          this.repContactList = this.mapToRepresentativeContacts(gqlContactUs);
          this.hasRelationshipManager = this.repContactList.some(
            (rep) => rep.representativeType === 'FLAGSHIP_REP',
          );
          this.flagshipData = this.mapToFlagshipDefaultDisplay(
            gqlContactUs,
            this.hasRelationshipManager,
          );
        } else {
          this.repContactList = [];
          this.hasRelationshipManager = false;
          this.flagshipData = undefined;
        }
      });
  }

  private mapToRepresentativeContacts(
    gqlContactUs: NonNullable<GQLContactUs>,
  ): RepresentativeContact[] {
    return (gqlContactUs?.representativeContacts || [])
      .filter((gqlRepresentative: GQLRepresentativeContact) =>
        this.isSupportedRepresentativeType(gqlRepresentative),
      )
      .map((gqlRepresentative: GQLRepresentativeContact) =>
        this.mapToRepresentativeContact(gqlRepresentative, gqlContactUs),
      );
  }

  private mapToFlagshipDefaultDisplay(
    gqlContactUs: NonNullable<GQLContactUs>,
    hasRelationshipManager: boolean,
  ): FlagshipServices | undefined {
    if (gqlContactUs?.flagshipClient && !hasRelationshipManager) {
      return {
        supportPhoneNumber: gqlContactUs.supportPhoneNumber,
        supportSchedule: gqlContactUs.supportSchedule[0],
        secureMessageUrl: content.linksContent.secureMessage.url,
      };
    }
    return undefined;
  }

  private mapToRepresentativeContact(
    gqlRepresentative: GQLRepresentativeContact,
    gqlContactUs: GQLContactUsWithoutRepresentativeContacts,
  ): RepresentativeContact {
    return {
      name: gqlRepresentative.name,
      title: gqlRepresentative.title,
      label: this.getRepresentativeLabel(gqlRepresentative),
      representativeType: gqlRepresentative.representativeType,
      webImagePath: environment.PERSONAL_DOMAIN + gqlRepresentative.webImagePath,
      directPhoneNumber: gqlRepresentative.directPhoneNumber,
      workingSchedule: this.getRepresentativeWorkingSchedule(gqlRepresentative, gqlContactUs),
      clientSupportPhoneNumber:
        gqlRepresentative.clientSupportPhoneNumber || gqlContactUs?.supportPhoneNumber || '',
      clientSupportWorkingSchedule: gqlRepresentative.clientSupportWorkingSchedule,
      appointmentUrl: this.getRepresentativeAppointmentUrl(gqlRepresentative, gqlContactUs),
      secureMessageUrl: content.linksContent.secureMessage.url,
    };
  }

  private getRepresentativeWorkingSchedule(
    gqlRepresentative: GQLRepresentativeContact,
    gqlContactUs: GQLContactUsWithoutRepresentativeContacts,
  ): {
    days: string;
    hours: string;
  }[] {
    const fallbackGqlSupportSchedules: GQLSupportSchedules = gqlContactUs?.supportSchedule || [];
    return (
      [
        gqlRepresentative.workingSchedule,
        gqlRepresentative.clientSupportWorkingSchedule,
        fallbackGqlSupportSchedules,
      ].find((gqlSchedules: GQLSupportSchedules) => gqlSchedules.length) || []
    );
  }

  private getRepresentativeLabel(gqlRepresentative: GQLRepresentativeContact): string | undefined {
    let label: string | undefined;
    if (gqlRepresentative.representativeType === 'RMA_ADVISOR') {
      label = content.contactUsContent.rmaClientLabel;
    } else if (gqlRepresentative.representativeType === 'FLAGSHIP_REP') {
      label = content.contactUsContent.flagshipClientLabel;
    }
    return label;
  }

  private getRepresentativeAppointmentUrl(
    gqlRepresentative: GQLRepresentativeContact,
    gqlContactUs: GQLContactUsWithoutRepresentativeContacts,
  ): string | undefined {
    let appointmentUrl: string | undefined;
    let gqlAppointmentUrl: string | undefined;
    if (gqlRepresentative.representativeType === 'RMA_ADVISOR') {
      gqlAppointmentUrl = gqlContactUs?.advisorAppointmentUrl;
    } else if (gqlRepresentative.representativeType === 'FLAGSHIP_REP') {
      gqlAppointmentUrl = gqlContactUs?.relationshipManagerAppointmentUrl;
    }
    if (gqlAppointmentUrl) {
      appointmentUrl = gqlAppointmentUrl.startsWith('http')
        ? gqlAppointmentUrl
        : environment.PERSONAL_DOMAIN + gqlAppointmentUrl;
    }
    return appointmentUrl;
  }

  private isSupportedRepresentativeType(gqlRepresentative: GQLRepresentativeContact): boolean {
    return (
      gqlRepresentative.representativeType === 'RMA_ADVISOR' ||
      gqlRepresentative.representativeType === 'FLAGSHIP_REP'
    );
  }
}
